<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="100px"
      :inline="false"
      size="normal"
    >
      <el-form-item label="专题名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入专题名称"
          maxlength="20"
        ></el-input>
      </el-form-item>

      <!-- <el-form-item label="英文专题" prop="title_en">
        <el-input v-model="form.title_en" placeholder="请输入英文专题名称"></el-input>
      </el-form-item> -->

      <el-form-item label="专题主图(横)" prop="pic_horizontal">
        <el-upload
          action="https://www.ddpurse.com/uploadImage"
          list-type="picture-card"
          :on-success="handleUploadSuccess"
          :on-remove="handleRemove"
          :file-list="picList"
          :before-upload="beforeUploadFile"
          accept="image/png,image/jpg,image/jpeg"
          :limit="1"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <div style="color:#ccc;font-size:12px">
          图片最佳尺寸800*300,图片大小小于2M
        </div>
      </el-form-item>

      <el-form-item label="专题主图(竖)" prop="pic_vertical">
        <el-upload
          action="https://www.ddpurse.com/uploadImage"
          list-type="picture-card"
          :on-success="handleEnUploadSuccess"
          :on-remove="handleEnRemove"
          :file-list="verticalList"
          :before-upload="beforeUploadFile"
          accept="image/png,image/jpg,image/jpeg"
          :limit="1"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <div style="color:#ccc;font-size:12px">
          图片最佳尺寸400*400,图片大小小于2M
        </div>
      </el-form-item>

      <el-form-item label="展示位置" prop="location">
        <el-select v-model="form.location" placeholder="请选择">
          <el-option
            v-for="item in positionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="排序编号" prop="sort" type="number">
        <el-input
          v-model="form.sort"
          type="number"
          min="0"
          max="99"
          placeholder="请输入排序编号，最高为0"
        ></el-input>
      </el-form-item>

      <el-form-item label="开始时间" prop="start_time">
        <el-date-picker
          v-model="form.start_time"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间"
        />
      </el-form-item>
      <el-form-item label="结束时间" prop="end_time">
        <el-date-picker
          v-model="form.end_time"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间"
        />
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="1">启动中</el-radio>
          <el-radio :label="-1">已停止</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">
          {{ type == "add" ? "立即创建" : "确认修改" }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { editNFTSubject, createNFTSubject } from "@/api/nft.js";

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "add",
    },
  },
  data() {
    const validateSort = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入排序编号"));
      } else if (+value < 0 || +value > 99) {
        callback(new Error("排序编号为0-99的整数"));
      }
      callback();
    };
    return {
      form: {
        status: -1,
      },

      rules: {
        name: [{ required: true, message: "请输入专题名称", trigger: "blur" }],
        // location: [{ required: true, message: "请选择展示位置", trigger: "change" }],
        sort: [
          { required: true, message: "请输入排序编号", trigger: "blur" },
          {
            validator: validateSort,
            trigger: "change",
          },
        ],
        // status: [{ required: true, message: "请选择专题状态", trigger: "change" }],
        // pic_horizontal: [{ required: true, message: "专题主图(横版)" }],
        // pic_vertical: [{ required: true, message: "专题主图(竖版)" }],
        // start_time: [
        //     { type: 'date', required: true, message: '请选择开始日期时间', trigger: 'change' }
        //   ],
        // end_time: [
        //     { type: 'date', required: true, message: '请选择结束日期时间', trigger: 'change' }
        //   ],
      },
      picList: [],
      verticalList: [],
      positionList: [
        { id: "none", name: "无" },
        { id: "banner", name: "专题banner" },
        { id: "waterfall", name: "瀑布流" },
      ],
    };
  },
  mounted() {
    console.log("mounted", this.data);
    if (this.type === "edit") {
      this.form = Object.assign({}, this.data);
      if (this.data.pic_horizontal) {
        this.picList = [
          {
            url: this.data.pic_horizontal,
          },
        ];
      }

      if (this.data.pic_vertical) {
        this.verticalList = [
          {
            url: this.data.pic_vertical,
          },
        ];
      }
    }
  },
  methods: {
    onSubmit() {
      console.log("onSubmit", { ...this.form });
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          console.log(valid, this.form);
          return false;
        }
      });
    },
    async submit() {
      // console.log("submit", { ...this.form });
      const action = this.type === "add" ? this.add : this.update;
      const success = await action();
      if (success) {
        this.$emit("success");
      }
    },

    async add() {
      try {
        const subjectData = this.getSubjectData();
        await createNFTSubject(subjectData);
        return true;
      } catch (error) {
        return false;
      }
    },
    async update() {
      try {
        const subjectData = this.getSubjectData();
        await editNFTSubject(subjectData);
        return true;
      } catch (error) {
        return false;
      }
    },

    getSubjectData() {
      let json = {
        name: this.form.name,
        pic_horizontal: this.form.pic_horizontal,
        pic_vertical: this.form.pic_vertical,
        sort: +this.form.sort,
        status: +this.form.status,
        location: this.form.location,
        start_time: this.form.start_time,
        end_time: this.form.end_time,
      };
      if (this.form.id) {
        json["id"] = this.form.id;
      }
      return json;
    },

    beforeUploadFile(file) {
      console.log("before upload");
      console.log(file);
      let size = file.size / 1024 / 1024;

      return new Promise((resolve, reject) => {
        if (size > 2) {
          this.$message.warning("文件大小不得超过2M");
          reject();
        } else {
          resolve(true);
        }
      });
    },

    // 上传和删除
    handleUploadSuccess(res, file) {
      this.form.pic_horizontal = res.data;
      this.$refs.form.clearValidate("pic_horizontal");
    },
    handleRemove(file, fileList) {
      this.form.pic_horizontal = "";
    },
    handleEnUploadSuccess(res, file) {
      this.form.pic_vertical = res.data;
      this.$refs.form.clearValidate("pic_vertical");
    },
    handleEnRemove(file, fileList) {
      this.form.pic_vertical = "";
    },
  },
};
</script>
