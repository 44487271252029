<template>
  <div style="padding: 20px">
    <div>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <el-radio-group
            @change="handleStatusChange"
            v-model="status"
            style="margin-bottom: 10px;"
          >
            <el-radio-button label="all">全部</el-radio-button>
            <el-radio-button :label="1">启动中</el-radio-button>
            <el-radio-button :label="-1">已停止</el-radio-button>
          </el-radio-group>
        </el-col>
        <el-col :span="12" :offset="0">
          <div style="text-align: right">
            <el-button type="primary" size="default" @click="showAdd">
              新增
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list" border stripe row-key="subject_id">
      <el-table-column label="专题Id" prop="subject_id"></el-table-column>
      <el-table-column label="专题名称" prop="name"></el-table-column>
      <el-table-column label="专题主图(横版)">
        <template slot-scope="scope" prop="pic_horizontal">
          <el-image
            :src="scope.row.pic_horizontal"
            fit="fill"
            :lazy="true"
            :preview-src-list="[scope.row.pic_horizontal]"
            style="max-width: 200px; max-height: 200px;"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="专题主图(竖版)）" prop="pic_vertical">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.pic_vertical"
            fit="fill"
            :lazy="true"
            :preview-src-list="[scope.row.pic_vertical]"
            style="max-width: 200px; max-height: 200px;"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="关联的nft合集数量" prop="nft_collection">
        <template slot-scope="scope">
          <div>{{ scope.row.nft_collection.length }}</div>
        </template>
      </el-table-column>
      <el-table-column label="展示位置" prop="location"></el-table-column>
      <el-table-column label="排序编号" prop="sort"></el-table-column>
      <el-table-column label="专题状态" prop="status">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.status === 1 ? 'success' : 'info'"
            size="normal"
          >
            {{ scope.row.status === 1 ? "启动中" : "已停止" }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="添加时间" prop="created_at">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status === -1"
            size="mini"
            type="warning"
            @click="changeStatus(scope.row)"
            style="margin-right: 10px"
          >
            启动
          </el-button>
          <el-button
            v-if="scope.row.status === 1"
            size="mini"
            type="danger"
            @click="changeStatus(scope.row)"
            style="margin-right: 10px"
          >
            停止
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="showEdit(scope.row)"
            style="margin-right: 10px"
          >
            编辑
          </el-button>
          <el-button
            size="mini"
            type="success"
            @click="showTransfer(scope.row)"
            style="margin-right: 10px"
          >
            关联nft
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="optionType === 'add' ? '新增专题' : '编辑专题'"
      :visible.sync="visible"
      width="600px"
      @close="closeDialog"
      destroy-on-close
    >
      <div v-if="visible">
        <SubjectForm
          :data="editSubject"
          :type="optionType"
          @success="handleSuccess"
        />
      </div>
    </el-dialog>

    <el-dialog
      title="关联nft"
      :visible.sync="transferVisible"
      width="800px"
      @close="closeTransferDialog"
      destroy-on-close
    >
      <div v-if="transferVisible">
        <el-transfer
          :titles="['nft合集库', '已选择nft合集']"
          v-model="selscted"
          :data="transferList"
          @change="handleChange"
          filterable
          :filter-method="filterMethod"
          filter-placeholder="请输入合集名称"
        >
          <span slot-scope="{ option }">{{ option.label }}</span>
        </el-transfer>
        <div style="display:flex;justify-content:flex-end;margin:20px 20px 0">
          <el-button
            type="primary"
            @click="onSubmit"
            style="margin-right: 20px"
          >
            保存
          </el-button>
          <el-button @click="closeTransferDialog">
            取消
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import SubjectForm from "./subjectForm.vue";
import {
  getNFTSubjectList,
  getNFTCollection,
  setNFTSubjectStatus,
  setNFTSubject,
} from "@/api/nft.js";

export default {
  components: { SubjectForm },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      status: "all",
      list: [],
      visible: false,
      editSubject: {},
      rules: {},
      optionType: "",

      transferTotal: 0,
      transferList: [],
      transferVisible: false,
      currentId: "",
      searchName: "",
      selscted: [],
    };
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
        };
        // 状态
        if (this.status !== "all") {
          params.status = +this.status;
        }
        const { data } = await getNFTSubjectList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTransferData() {
      try {
        const params = {};
        // 搜索
        if (this.searchName) {
          params.name = this.searchName;
        }
        const { data } = await getNFTCollection(params);
        const { total = 0, items = [] } = data?.data;
        const generateData = (_) => {
          const data = [];
          items.forEach((item, index) => {
            data.push({
              label: item.name,
              key: item.id,
              name: item.name,
            });
          });
          return data;
        };
        this.transferTotal = total;
        this.transferList = generateData();
      } catch (error) {
        console.log(error);
      }
    },
    async changeStatus(row) {
      console.log(row);
      try {
        const params = {
          id: row.id,
          status: row.status == 1 ? -1 : 1,
        };
        const { data } = await setNFTSubjectStatus(params);
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },

    closeDialog() {
      this.visible = false;
      this.editSubject = {};
      this.optionType = "";
    },
    // 展示穿梭框
    showTransfer(row) {
      this.currentId = row.id;
      this.selscted = row.nft_collection;
      this.transferVisible = true;
      this.fetchTransferData();
    },
    // 关闭
    closeTransferDialog() {
      this.transferVisible = false;
      this.selscted = [];
      this.searchName = "";
      this.transferTotal = 0;
    },
    // 改变穿梭框
    handleChange(value, direction, movedKeys) {
      this.selscted = value;
      // console.log(value, direction, movedKeys);
    },
    // 穿梭框提交
    async onSubmit() {
      try {
        const params = {
          id: this.currentId,
          nft_collection: this.selscted,
        };
        const { data } = await setNFTSubject(params);
        this.closeTransferDialog();
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },
    // 输入搜索值
    changeSearch(value) {
      console.log(value);
    },

    filterMethod(query, item) {
      return item.name.indexOf(query) > -1;
    },

    showEdit(row) {
      this.visible = true;
      this.optionType = "edit";
      this.editSubject = row;
    },
    showAdd() {
      this.visible = true;
      this.optionType = "add";
      this.editSubject = {};
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleStatusChange() {
      this.pageSize = 20;
      this.page = 1;
      this.fetchData();
    },
    handleSuccess() {
      this.fetchData();
      this.closeDialog();
    },
  },
};
</script>

<style lang="less">
.el-transfer-panel {
  width: 280px !important;
}
</style>
